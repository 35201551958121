/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

 import "@fontsource/titillium-web/300.css"
 import "@fontsource/titillium-web/600.css"
 import "@fontsource/titillium-web/700.css"
 import "@fontsource/roboto/300.css"
 import "@fontsource/roboto/400.css"
 import "@fontsource/roboto/700.css"
 
  // You can delete this file if you're not using it
 export const onRouteUpdate = ({ location }) => {
 
 }